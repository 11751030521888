#app
  padding: 1rem
  font-size: 14px
fieldset
  label
    font-size: 16px
    border-bottom: 1px solid #dc3545
.input-padding
  padding-left: 8px
  padding-right: 8px
.form-control.invalid
  border-color: #dc3545
.form-control.invalid:focus
    border-color: #dc3545
    box-shadow: 0 0 0 0.2rem #dc354563
.arrows 
  padding: 0
.arrows BUTTON
  display: inline-block
  //border: 1px solid #f00
  background: none
  border: none
  outline: 0
  text-align: center
  color: #777
  padding: 0
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  position: relative
.arrows BUTTON:before
  position: absolute
  content: ''
  border-radius: 50%
  padding: 93%
  left: -40%
  top: 0
  z-index: -1
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
.arrows BUTTON:active:before
  background-color: #DDD
.arrows BUTTON:disabled
  color: #CCC
.arrows BUTTON:disabled:active:before
  background-color: initial
.arrows i.fa
  font-size: 3rem
  line-height: 2.2rem
.arrows.left BUTTON
  padding-right: .2rem
.arrows.right BUTTON
  padding-left: .2rem
.next-date.disabled
  color: #CCC
.btn-load
  display: flex
  justify-content: center
  align-items: center
  width: 35px
  .fa
    flex: 0 0 40px

.curr-date strong
  border-bottom: 1px dashed #777
  cursor: pointer

.date-select
  width: 400px
  max-width: unset
  left: 50%
  transform: translateX(-50%)
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1)
  top: 20px

  padding: 20px

  .arrow
    left: 50%
    transform: translateX(-50%)

.load-button 
  position: relative
  width: 30px

  .tooltip
    width: max-content
    .arrow
      left: calc(15px - .4rem)

    &.danger .tooltip-inner
      background-color: #dc3545
      color: white

    &.danger .arrow::before
      border-bottom-color: #dc3545
